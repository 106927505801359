import * as Yup from "yup";
import { YUP } from "../../../utils/validations";

const DepartmentDetailsSchema = Yup.object().shape({
	name: Yup
		.string()
		.required(YUP.REQUIRED)
});
const StorySchema = Yup.object().shape({
	Title:Yup.string().required(),
	 image: Yup.array().required("Required"),
	 thumbnail: Yup.mixed().required("Required"),
});
const StoryEditSchema = Yup.object().shape({
	Title:Yup.string().required(),
	 
});

const UniversityDetailsSchema = Yup.object().shape({
	name: Yup
		.string()
		.required(YUP.REQUIRED),
		max_installment: Yup
		.number()
		.required(YUP.REQUIRED).min(1,"must be greater than or equal to 1")
});


export  {DepartmentDetailsSchema,StorySchema,UniversityDetailsSchema,StoryEditSchema};