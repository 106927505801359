import React, { useEffect, useState } from "react";
import moment from "moment";
// import { useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Layout from "../../../../components/Layout/index";
import commonStyles from "../../../common/styles/common.module.css";
import { getAddons } from "../../api";
import Pagination from "../../../../components/Pagination";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { useAppDispatch } from "../../../../redux/hooks";
import Add from "../../modals/AddOns/Add";
import { addonModal } from "../../../../redux/reducers/addon";
import DeleteAddon from "../../modals/AddOns/Delete";
import { INSTRUCTOR } from "../../../../utils/instructor";

const Addon = () => {
  // const { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [addons, setAddons] = useState<any>([]);
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    getAddons(query).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setAddons(data);
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    if (!showModal) refresh();
  }, [pagination.page, showModal, showDeleteModal]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Add Ons</h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${commonStyles.activeTab}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => {
                    dispatch(
                      addonModal({
                        id: 0,
                        editOrAdd: INSTRUCTOR.REDUX.ADD,
                      })
                    );
                    setShowModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Add-On
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Price</th>

                        <th className="text-center">Created At</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addons.map((item: any) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.type === 1 ? "Online" : "Offline"}</td>
                          <td>{item.price} KD</td>
                          <td className="text-center">
                            {moment(item.created_at).format("MMM DD, YYYY")}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              drop={"down-centered"}
                              className={commonStyles.tddropdown}
                            >
                              <Dropdown.Toggle>
                                <a className="me-2"></a>
                                <FontAwesomeIcon icon={faEllipsisH} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    dispatch(
                                      addonModal({
                                        id: item.id,
                                        editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                      })
                                    );
                                    setShowModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    size="xs"
                                    className={commonStyles.iconmargin}
                                    icon={faEdit}
                                  />
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    dispatch(
                                      addonModal({
                                        id: item.id,
                                        editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                      })
                                    );
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    size="xs"
                                    className={commonStyles.iconmargin}
                                    icon={faTrash}
                                  />
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <Add showModal={showModal} handleClose={handleClose} />
      <DeleteAddon
        showModal={showDeleteModal}
        handleClose={handleDeleteClose}
      />
    </Layout>
  );
};

export default Addon;
