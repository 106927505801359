import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { uploadVideo } from "../../common/utils";
import { RemoveInstructorRespose } from "../../instructor/api/interface";
import { AddCourseParam, Addon, ApproveCoursePayload, Chapter, ChaptersResponse, Course, GetAddonResponse, GetSpecificAddonResponse, ParticularChapterResponse } from "./interface";


export const courseList = (query?:Pagination,university_id?:number,department_id?:number,for_coupons?:boolean,student_id?:number): Promise<Course> => {
	return axios.get(API_ROUTES.COURSES.GETCOURSELIST, {params:{...query,...((university_id&&university_id!==0)&&{university_id}),...((department_id)&&{department_id}),...(for_coupons&&{for_coupons}),...(student_id&&{student_id})}});
};

export const addCourse=async (params:AddCourseParam,videoFile:File|null):Promise<RemoveInstructorRespose>=>{
	// uploadVideo(videoFile).then((res)=>{
	// 	return axios.post(API_ROUTES.COURSES.GETCOURSELIST, params);
	// })
if(videoFile){
	const guid=await uploadVideo(videoFile);
	return axios.post(API_ROUTES.COURSES.GETCOURSELIST, {...params,intro_video:guid,library_id:'269356'});}
	else{
		return axios.post(API_ROUTES.COURSES.GETCOURSELIST, {...params});
	}
	
}

export const editCourse=async (params:AddCourseParam, id:number,videoFile:File|null):Promise<RemoveInstructorRespose>=>{
	if(videoFile){
		const guid=await uploadVideo(videoFile);
		return axios.post(`${API_ROUTES.COURSES.GETCOURSELIST}/${id}`, {...params,intro_video:guid,library_id:'269356'});
	}
	else{

	return axios.post(`${API_ROUTES.COURSES.GETCOURSELIST}/${id}`, params);}
}
export const editCourseStatus=(state_id:number, id:number):Promise<RemoveInstructorRespose>=>{
	return axios.post(`${API_ROUTES.COURSES.CHANGE_STATUS}${id}`, {state_id});
}

export const course = (id:string, query:PaginationInterface): Promise<Chapter> => {
	console.log(query)
	return axios.get(`${API_ROUTES.COURSES.GETCOURSE}${id}`);
};

export const getChapter=(id:string, query:Pagination): Promise<ChaptersResponse>=>{
	return axios.get(API_ROUTES.COURSES.GETCHAPTERS, {params:{...query, course_id:id}});
}
export const getParticularChapter=(id:number):Promise<ParticularChapterResponse>=>{
	return axios.get(`${API_ROUTES.COURSES.GETCHAPTERS}/${id}`);
}

export const approveCourse=(query:ApproveCoursePayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.COURSES.APPROVECOURSE, query)

}
export const removeCourse=(id:number):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.COURSES.REMOVECOURSE}${id}`);
}
export const editChapter=(id:number, online_price:number, course_id:number):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.COURSES.GETCHAPTERS}/${id}`, {price:online_price, course_id})
}

export const getAddons=(query:Pagination): Promise<GetAddonResponse> => {
	return axios.get(API_ROUTES.COURSES.GETADDONSLIST, {params:query});
};
export const getSpecificAddons=(id:String): Promise<GetSpecificAddonResponse> => {
	return axios.get(`${API_ROUTES.COURSES.GETADDONSLIST}/${id}`);
};

export const addAddon=(addOnPayload:Addon) => {
	return axios.post(API_ROUTES.COURSES.GETADDONSLIST, addOnPayload);
};
export const editAddon=(addOnPayload:Addon, id:string) => {
	return axios.post(`${API_ROUTES.COURSES.GETADDONSLIST}/${id}`, addOnPayload);
};
export const deleteAddon=(id: String)=>{
	return axios.delete(`${API_ROUTES.COURSES.GETADDONSLIST}/${id}`)
}

