import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEllipsisH,
  faEdit,
  faEye,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Button, Dropdown, Form, InputGroup, Spinner } from "react-bootstrap";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { getAllUniversities, list, removeInstructor } from "../api";
import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
import { Avatar } from "../../../assets/images";
import { Filter, Instructor, UniversityInterface } from "../api/interface";
import Pagination from "../../../components/Pagination";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { MESSAGES } from "../../../utils/messages";
import { APP_ROUTES } from "../../../utils/routes";
import { ROUTE_CONSTANTS } from "../../../routes/constants";
import { MESSAGES } from "../../../utils/messages";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../redux/hooks";

const InstructorList = () => {
  const navigate = useNavigate();
  const [universities, setUniversities] = useState<UniversityInterface[]>([]);

  useEffect(() => {
    getAllUniversities().then((res) => {
      setUniversities(res?.data);
    });
  }, []);

  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [filter, setFilter] = useState<Filter>({
    sort: -1,
    university_id: -1,
    status: -1,
  });
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const searchText = useAppSelector((state) => state.searchReducer.text);

  useEffect(() => {
    setSearchValue(searchText as string);
  }, [searchText]);

  const handleSearch = () => {
    setSearchValue(search);
  };

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      full_name: searchValue,
    };

    list(query, filter).then((res) => {
      setPagination({
        ...pagination,
        count: res.paginate.total,
        totalPages: res.paginate.last_page,
      });

      setInstructors(res.data);
      setLoading(false);
    });
  };

  const handleRemoveInstructor = (id: Number) => {
    toast.promise(removeInstructor({ id }), {
      pending: {
        render() {
          return MESSAGES.INSTRUCTOR.REMOVING;
        },
      },
      success: {
        render({}) {
          refresh();
          return MESSAGES.INSTRUCTOR.REMOVED;
        },
      },
      error: {
        render() {
          return MESSAGES.INSTRUCTOR.NOTREMOVED;
        },
      },
    });
  };

  const addNewInstructor = () => {
    // dispatch(instructorModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
    navigate(APP_ROUTES.INSTRUCTORS_ADD);
  };

  const editInstructor = (id: Number) => {
    navigate(`${APP_ROUTES.INSTRUCTORS_LIST}/${id}`);
  };
  const viewCourses = (id: Number) => {
    navigate(`${ROUTE_CONSTANTS.INSTRUCTORS_COURSES}/${id}`);
  };

  useEffect(() => {
    refresh();
  }, [pagination.page, searchValue, filter]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        {
          <>
            <span className="instr_manager d-flex justify-content-between align-items-center">
              <h3>Instructor Management</h3>

              <div className={commonStyles["heaing-filter"]}>
                <div className={commonStyles["filter-left"]}>
                  <span>
                    Status:
                    <select
                      onChange={(e) =>
                        setFilter((prev) => ({
                          ...prev,
                          status: Number(e.target.value),
                        }))
                      }
                    >
                      <option value={-1}>Select</option>
                      <option value={1}>Active</option>
                      <option value={3}>Blocked</option>
                  
                    </select>
                  </span>
                  <span>
                    Sort:
                    <select
                      onChange={(e) =>
                        setFilter((prev) => ({
                          ...prev,
                          sort: Number(e.target.value),
                        }))
                      }
                    >
                      <option value={-1}>Select</option>

                      <option value={0}>Latest First</option>
                      <option value={1}>Oldest First</option>
                    </select>
                  </span>
                  <span>
                    University:
                    <select
                      onChange={(e) =>
                        setFilter((prev) => ({
                          ...prev,
                          university_id: Number(e.target.value),
                        }))
                      }
                    >
                      <option value={-1}>Select</option>
                      {universities.map((uni) => (
                        <option value={uni.id}>{uni.name}</option>
                      ))}
                    </select>
                  </span>
                  <span></span>
                </div>
              </div>
            </span>
          </>
        }
        {!loading ? (
          <>
            <div className={`light-theme-table ${commonStyles["table-data"]}`}>
              <div className={`${commonStyles["property-head"]} pb-1`}>
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  {/* <InputGroup className={`${commonStyles["search-bar"]} mb-3`}>
                    <Form.Control
                      placeholder="Search Name"
                      type="text"
                      value={search}
                      aria-label="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      aria-describedby="basic-addon2"
                    />
                    <Button
                      onClick={handleSearch}
                      variant="outline-secondary"
                      id="button-addon2"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup> */}
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${commonStyles.activeTab}`}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      All
                    </button>
                  </li>
                </ul>
                <div className={commonStyles["filter-pro"]}>
                  <button
                    onClick={addNewInstructor}
                    className={commonStyles["add-lead"]}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add New Instructor
                  </button>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div style={{ overflowX: "auto" }}>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Mobile No</th>
                          <th className="text-center">Total Courses</th>
                          <th className="text-center">Total Students</th>
                          <th>Registered At</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {instructors.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <img
                                src={item.avatar ? item.avatar : Avatar}
                                height={40}
                                width={40}
                                style={{borderRadius:"50%"}}
                                alt="avatar"
                              />
                              {item.full_name.trim()}
                            </td>
                            <td>
                              +{item.country_code} {item.mobile}
                            </td>
                            <td className="text-center">
                              {item.total_courses}
                            </td>
                            <td className="text-center">
                              {item.total_students}
                            </td>
                            <td>
                              {moment(item.created_at).format("MMM DD, YYYY")}
                            </td>
                            <td>
                              <span
                                className={
                                  item.banned
                                    ? commonStyles["bl-shd"]
                                    : `${commonStyles["bl-shd"]} ${commonStyles.grn}`
                                }
                              >
                                {item.banned ? "Blocked" : "Active"}
                              </span>
                            </td>
                            <td>
                              <Dropdown
                                drop={"down-centered"}
                                className={commonStyles.tddropdown}
                              >
                                <Dropdown.Toggle>
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => viewCourses(item.id)}
                                  >
                                    <FontAwesomeIcon icon={faEye} size="xs" />
                                    View Courses
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => editInstructor(item.id)}
                                  >
                                    <FontAwesomeIcon icon={faEdit} size="xs" />{" "}
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleRemoveInstructor(item.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} size="xs" />{" "}
                                    Remove
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
    </Layout>
  );
};

export default InstructorList;
