import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { APP_ROUTES } from "../../../utils/routes";
import { Pagination } from "../../common/interface";
import { RemoveInstructorPayload, RemoveInstructorRespose } from "../../instructor/api/interface";
import { AddCommonDepartmentPayload, AddDepartmentPayload, CommonDepartmentPayload, DepartmentPayload, DepartmentResponse, GetDepartment, StoryResponse, UniversityResponse } from "./interface";

export const universityList = (query: any): Promise<UniversityResponse> => {
	return axios.get(API_ROUTES.COMMON.GETALLUNIVERSITIES, { params: query });
};

export const departmentList= (query:Pagination, param:DepartmentPayload): Promise<DepartmentResponse>=>{
	return axios.get(API_ROUTES.UNIVERSITIES.DEPARTMENTS, {params:{...query, ...param}})
}
export const commonDepartmentList= (query:any, param?:CommonDepartmentPayload): Promise<DepartmentResponse>=>{
	return axios.get(API_ROUTES.UNIVERSITIES.CATEGORY, {params:{...query}})
}
export const addDepartment=(university_id:number, payload: AddDepartmentPayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS, {university_id:university_id, ...payload, type:1})
}
export const addCommonDepartment=( payload: AddCommonDepartmentPayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.UNIVERSITIES.CATEGORY, { ...payload, status:1,icon:payload.file})
}
export const removeDepartment=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${query.id}`)
}
export const removeCategory=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.UNIVERSITIES.CATEGORY}/${query.id}`)
}


export const getDepartment=(departmentId:number):Promise<GetDepartment>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${departmentId}`)
}
export const getCategory=(departmentId:number):Promise<GetDepartment>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.CATEGORY}/${departmentId}`)
}
// export const getCommonDepartment=(departmentId:number):Promise<GetDepartment>=>{
// 	return axios.get(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${departmentId}`)
// }

export const editDepartment=(universityId:number, departmentId:Number, payload:AddDepartmentPayload):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${departmentId}`, {university_id:universityId, type:1, ...payload})
}

export const editCommonDepartment=(departmentId:Number, payload:AddCommonDepartmentPayload):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.UNIVERSITIES.CATEGORY}/${departmentId}`, { type:1, ...payload})
}


export const addCategory=(name:string,file:string):Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.UNIVERSITIES.CATEGORY, {name,icon:file})
	
}
export const addUniversity=(max_installment:string,name:string,file?:string):Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES, {max_installment,name,file})}

export const removeUniversity=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES}/${query.id}`)
}
export const removeStory=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${"admin/delete-story"}/${query.id}`)
}

export const editUniversity=(max_installment:string,id:Number, name:string,file?:string):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES}/${id}`, {max_installment,name,file})
}
export const getUniversity=(id:number):Promise<GetDepartment>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES}/${id}`)
}

export const getStory=(query: Pagination,university_id:number):Promise<StoryResponse>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.STORY}`,{params:{university_id,...query}})
}
export const getSpecificStory=(story_id:number):Promise<any>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.STORY}/${story_id}`)
}
export const addStory=(university_id:number,source:string,thumbnail:string,Title:string)=>{
	return axios.post(`${API_ROUTES.UNIVERSITIES.ADMIN_STORY}`,{university_id,source,media_type:1,title:Title,thumbnail})
}
export const editStory=(university_id:number,story_id:number,Title:string,source?:any,thumbnail?:string)=>{
	return axios.post(`${API_ROUTES.UNIVERSITIES.ADMIN_STORY}/${story_id}`,{university_id,...(source&&{source}),media_type:1,title:Title,...(thumbnail&&{thumbnail})})
}