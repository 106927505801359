import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { RemoveInstructorRespose } from "../../instructor/api/interface";
import {  CouponListResponse, CouponDetails, SpecificCouponResponse, TimerResponse } from "./interface";

export const addCoupon=(params: CouponDetails):Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.COUPONS.GETLIST, params);
}


export const couponList = (query: Pagination,type:Number): Promise< CouponListResponse> => {
	return axios.get(API_ROUTES.COUPONS.GETLIST, { params: {...query,...(type!==0&&{type})} });
};

export const coupon = (id:string,detail:any): Promise<SpecificCouponResponse> => {
	
	return axios.get(`${API_ROUTES.COUPONS.GETLIST}/${id}`,{params:{...detail}});
};
export const editCoupon=(params:CouponDetails, id:number):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.COUPONS.GETLIST}/${id}`, params);
};

export const getTimer=():Promise<TimerResponse>=>{
	return axios.get(`${API_ROUTES.COUPONS.GETTIMER}`);

}
// export const setTimer=(duration:number,type:number):Promise<any>=>{
// 	axios.post(`${"/admin/set-timer"}`,{duration,type})
// }
export const setTimer=(duration:number, type:number)=>{
	return axios.post(`${API_ROUTES.COUPONS.SETTIMER}`, {duration, type});
};